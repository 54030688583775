<template>
  <div class="head_top">
    <img src="../../../../../public/images/二级海报/二级页面海报室内机.jpg" width="100%" height="100%" @click="">
    <!--    室内云台摄像机（500万像素）-->
    <div class="content1">
      <div class="content1_title">
        <div class="content1_title_size">室内云台摄像机（400万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/1.WIFI摄像机/室内机详情（500万像素）/Z76Q/Z76Q.jpg" names="Z76Q" ulr="/Z76Q"></PhotoCard>
      </div>
    </div>
    <!--    室内云台摄像机（400万像素）-->
    <div class="content1">
      <div class="content1_title">
        <div class="content1_title_size">室内云台摄像机（400万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/二级页面/室内机/400万像素白底图/CS48Q.jpg" names="CS48Q" ulr="/CS48Q"></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/400万像素白底图/CS22Q.jpg" names="CS22Q" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/400万像素白底图/CS26Q.jpg" names="CS26Q" ulr=""></PhotoCard>
      </div>
    </div>

    <!--    室内云台摄像机（200万像素）-->
    <div class="content2">
      <div class="content1_title">
        <div class="content1_title_size">室内云台摄像机（200万像素）</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/1.WIFI摄像机/室内机详情（200万像素）/Z71/Z71.jpg" names="Z71" ulr="/Z71"></PhotoCard>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C24S.jpg" names="C24S" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C26S.jpg" names="C26S" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C29S.jpg" names="C29S" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C37S白.jpg" names="C37S白" ulr=""></PhotoCard>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C37S黑.jpg" names="C37S黑" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C38.jpg" names="C38" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C38S-P.jpg" names="C38S" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/200万像素白底图/C43S.jpg" names="C43S" ulr=""></PhotoCard>
      </div>
    </div>

    <!--    室内电池摄像机-->
    <div class="content1dc">
      <div class="content1_title">
        <div class="content1_title_size">室内电池摄像机</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/二级页面/室内机/电池摄像机/CB53.png" names="CB53" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/电池摄像机/CB71.jpg" names="CB71" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/电池摄像机/CB72.jpg" names="CB72" ulr=""></PhotoCard>
      </div>
    </div>

    <!--    室内全景摄像机-->
    <div class="content1">
      <div class="content1_title">
        <div class="content1_title_size">室内全景摄像机</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/二级页面/室内机/全景摄像机/C60S.jpg" names="C60S" ulr=""></PhotoCard>
        <PhotoCard img="images/二级页面/室内机/全景摄像机/C61S.jpg" names="C61S" ulr=""></PhotoCard>
      </div>
    </div>


  </div>
</template>

<script>
import PhotoCard from '../../../../../src/components/photo-card.vue'

export default {
  name: "index",
  components: {
    PhotoCard
  },
  data() {
    return {}
  },
  methods: {
    CS48Q() {
      this.$router.push('/CS48Q')
    }
  }
}
</script>

<style scoped>
.content1 {
  padding-top: 80px;
  width: 80%;
  height: 400px;
  margin: auto;
  /*background: #cd6969;*/
}
.content1dc {
  padding-top: 180px;
  width: 80%;
  height: 400px;
  margin: auto;
  /*background: #cd6969;*/
}

.content2 {
  padding-top: 40px;
  width: 80%;
  height: 800px;
  margin: auto;
  /*background: #cd6969;*/
}

.content1_title {
  display: flex;
  justify-content: space-between;
}

.content1_title_size {
  font-size: 20px;
  color: red;
}


#grid {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fill, 150px);*/
  /*grid-column-gap: 130px;*/
  /*grid-row-gap: 130px;*/
  align-items: center;
  width: 100%;
  /*justify-content: center;*/
  /*max-width: 800px;*/
  display: flex;
  justify-content: space-evenly;
}

#grid .card {
  background-color: #ccc;
  width: 150px;
  height: 150px;
  transition: all 0.1s ease;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  overflow: hidden;
  cursor: pointer;
}

#grid .card:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

#grid .card:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#grid .card .reflection {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  transition: all 0.1s ease;
  opacity: 0;
  mix-blend-mode: soft-light;
}

#grid .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: grayscale(0.65);
  filter: grayscale(0.65);
  transition: all 0.3s ease;
}

.grid {
  padding-top: 100px;
}

p {
  color: red;
  font-size: 100px;
}
</style>